// ** React Imports
import {
  useState,
  ElementType,
  ChangeEvent,
  useEffect,
  useCallback,
} from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

// ** Third Party Components Imports
import axios from "axios";
import yup from "../../../../helpers/customYup";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

// ** Custom Components Imports
import { Avatar } from "../../../../comps/avatar";

// ** Hooks Imports
import { useAuth } from "../../../../hooks/useAuth";

// ** Utils Imports
import { getInitials } from "../../../../helpers/get-initials";

// ** Types Imports
import { ButtonProps } from "@mui/material/Button";
import { useAlertContext } from "../../../../hooks/useAlertContext";
import { clearSpace } from "../../../../helpers/clearSpace";
import { UserDataType } from "../../../../context/types";
import { LinearProgress } from "@mui/material";

const accountDetailsSchema = yup.object().shape({
  displayName: yup.string().required(),
  password: yup.string(),
});

interface AccountDetailsData
  extends yup.InferType<typeof accountDetailsSchema> {}

const defaultValues = {
  displayName: "",
  password: "",
};

const ButtonStyled = styled(Button)<
  ButtonProps & { component?: ElementType; htmlFor?: string }
>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    textAlign: "center",
  },
}));

const ResetButtonStyled = styled(Button)<ButtonProps>(({ theme }) => ({
  marginLeft: theme.spacing(4),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    marginLeft: 0,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
}));

export const TabAccountScreen = () => {
  // ** States
  const [avatarSrc, setAvatarSrc] = useState<string>("");
  const [avatarFile, setAvatarFile] = useState<File | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // ** Hooks
  const { user, setUser } = useAuth();
  const { t } = useTranslation();
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountDetailsData>({
    defaultValues,
    resolver: yupResolver(accountDetailsSchema),
  });
  const { showSuccessAlert, showErrorAlert } = useAlertContext()
  const { i18n } = useTranslation()

  const handleLangItemClick = (event: SelectChangeEvent) => {
    const value = event.target.value as 'en' | 'sk' | 'cs'

    i18n.changeLanguage(value);
    localStorage.setItem('lang', value);
  }

  const handleAvatarChange = (event: ChangeEvent) => {
    const { files } = event.target as HTMLInputElement;
    if (files && files.length !== 0) {
      setAvatarFile(files[0]);
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        setAvatarSrc(reader.result as string);
      };
    }
  };

  const handleAvatarReset = () => {
    if (user) {
      setAvatarFile(null);
      if (user.avatar) {
        setAvatarSrc(user.avatar);
      } else {
        setAvatarSrc("");
      }
    }
  };

  const handleAvatarUpload = async (form: FormData ) => {
    return await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/upload/single`,
      form,
      {
        timeout: 180000,
      }
    );
  };

  const handleAdminDataSubmit = async (data: any, user: UserDataType) => {
    return await axios
      .patch(
        `${process.env.REACT_APP_SERVER_HOST}/admin/update/${user._id}`,
        data,
        { withCredentials: true }
      )
      .then((res) => {
        setUser(res.data.admin)
        showSuccessAlert(t("Account details updated!"));
      })
      .catch((err) => {
        console.log(err);
        showErrorAlert(t("Error during update! Check console"));
      });
  }

  const handleAccountDetailsSubmit = async (data: AccountDetailsData) => {
    if (!user) return;
    setIsSubmitting(true);

    const isEmpty = clearSpace(String(data.password)).length === 0;

    // prevents empty string from being sent for update
    if (isEmpty || !data.password) {
      delete data.password;
    }

    if (avatarFile) {
      const form = new FormData();

      form.append("image", avatarFile);
      form.append("folder", user._id);

      await handleAvatarUpload(form)
        .then((res) => {
          if (res.data.url) {
            showSuccessAlert(t("Account image updated!"));

            const payload = {
              ...data,
              avatar: res.data.url,
            };

            handleAdminDataSubmit(payload, user);
          }
        })
        .catch((err) => {
          console.error(err);
          showErrorAlert(t("Error during upload! Check console"));
        });

      setIsSubmitting(false);
    } else {
      await handleAdminDataSubmit({ ...data }, user);
      setIsSubmitting(false);
    }
  };

  const handleAccountDetailsReset = useCallback(() => {
    if (user) {
      setValue("displayName", user.displayName);
    }
  }, [user, setValue]);

  useEffect(() => {
    if (user && user.avatar) {
      setAvatarSrc(user.avatar);
    }
    handleAccountDetailsReset();
  }, [user, handleAccountDetailsReset]);

  if (!user) return null;

  return (
    <Grid container spacing={6}>
      {/* Account Details Card */}
      <Grid item xs={12}>
        <Card>
          <CardHeader title={t("Account details")} />
          <form>
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {avatarSrc ? (
                  <Avatar
                    alt="Profile Pic"
                    src={avatarSrc}
                    skin="light"
                    variant="rounded"
                    sx={{
                      width: 120,
                      height: 120,
                      mr: 5,
                      fontWeight: 600,
                      fontSize: "3rem",
                    }}
                  />
                ) : (
                  <Avatar
                    alt="Profile Pic"
                    skin="light"
                    variant="rounded"
                    sx={{
                      width: 120,
                      height: 120,
                      mr: 5,
                      fontWeight: 600,
                      fontSize: "3rem",
                    }}
                  >
                    {getInitials(user.displayName)}
                  </Avatar>
                )}
                <div>
                  <ButtonStyled
                    component="label"
                    variant="contained"
                    htmlFor="account-settings-upload-image"
                  >
                    {t("Upload new photo")}
                    <input
                      hidden
                      type="file"
                      accept="image/png, image/jpg, image/jpeg"
                      onChange={handleAvatarChange}
                      id="account-settings-upload-image"
                    />
                  </ButtonStyled>
                  <ResetButtonStyled
                    color="secondary"
                    variant="outlined"
                    onClick={handleAvatarReset}
                  >
                    {t("Reset")}
                  </ResetButtonStyled>
                  <Typography sx={{ mt: 5, color: "text.disabled" }}>
                    {t(
                      "Pridať obrázky je možné iba vo formáte *.jpeg, *.jpg, *.png"
                    )}
                  </Typography>
                </div>
              </Box>
            </CardContent>

            <Divider />

            <CardContent>
              <Grid container spacing={6}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <Controller
                      name="displayName"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <TextField
                          required
                          value={value}
                          label={t("Display name")}
                          onChange={onChange}
                          error={Boolean(errors.displayName)}
                        />
                      )}
                    />
                    {errors.displayName && errors.displayName.message && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {t(errors.displayName.message)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="admin-password"
                      error={Boolean(errors.password)}
                    >
                      {t("Password")}
                    </InputLabel>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <OutlinedInput
                          value={value}
                          autoComplete="new-password"
                          label={t("Password")}
                          onChange={onChange}
                          id="admin-password"
                          error={Boolean(errors.password)}
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                onMouseDown={(e) => e.preventDefault()}
                                onClick={() => setShowPassword(!showPassword)}
                              >
                                {showPassword ? (
                                  <Icon>visibility</Icon>
                                ) : (
                                  <Icon>visibility_off</Icon>
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      )}
                    />
                    {errors.password && errors.password.message && (
                      <FormHelperText sx={{ color: "error.main" }}>
                        {t(errors.password.message)}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {isSubmitting && (
                  <Grid item xs={12}>
                    <LinearProgress />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{ mr: 3 }}
                    disabled={isSubmitting}
                    onClick={handleSubmit(handleAccountDetailsSubmit)}
                  >
                    {t("Save changes")}
                  </Button>
                  <Button
                    type="reset"
                    variant="outlined"
                    color="secondary"
                    onClick={handleAccountDetailsReset}
                  >
                    {t("Reset")}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth>
                    <InputLabel id="profile-language-selector-label">
                      Language
                    </InputLabel>
                    <Select
                      labelId="profile-language-selector-label"
                      id="demo-simple-select"
                      value={i18n.language}
                      label="Langage"
                      onChange={handleLangItemClick}
                    >
                      <MenuItem sx={{ py: 2 }} value="en">
                        English
                      </MenuItem>

                      <MenuItem sx={{ py: 2 }} value="sk">
                        Slovak
                      </MenuItem>

                      <MenuItem sx={{ py: 2 }} value="cs">
                        Czech
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </form>
        </Card>
      </Grid>
    </Grid>
  );
};
