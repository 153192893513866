import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { ComponentType, useEffect } from "react";
import { fetchDocsNavLink } from "../store/apps/docs";
import { BookOpenOutline } from "mdi-material-ui";
import { DocLinkT } from "../types/doc-types";
import { VerticalNavItemsType } from "../comps/@core/layouts/types";
import UserManualPage from "../screens/settings/docs/user-manual";
import TechnicalDocPage from "../screens/settings/docs/technical";
import AddUserManualScreen from "../screens/settings/docs/user-manual/add-user-manual";
import AddTechnicalDocScreen from "../screens/settings/docs/technical/add-technical-doc";

/**
 * @description Build the Documentation Navigation
 */
const useDocsNav = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { technicalDocsLinks, userManualLinks } = useSelector(
    (state: RootState) => state.docs.links
  );

  useEffect(() => {
    dispatch(fetchDocsNavLink());
  }, [dispatch]);

  const linkMapper = (links: DocLinkT[], element: ComponentType) => {
    const navLinkBuilder = (links: DocLinkT[]): VerticalNavItemsType => {
      const sortedLinks = [...links].sort((a, b) => a.position - b.position);

      return sortedLinks.map(({ children, ...link }) => {
        if (!children.length) {
          return {
            title: link.title,
            path: link.path,
            element,
          };
        }

        return {
          title: link.title,
          children: [
            {
              title: "Index",
              path: link.path,
              element,
            }, // Primary document
            ...navLinkBuilder(children), // Recursively map children
          ],
        };
      });
    };

    return navLinkBuilder(links);
  };

  const tLinks = linkMapper(technicalDocsLinks, TechnicalDocPage);
  const uLinks = linkMapper(userManualLinks, UserManualPage);

  const links = [
    {
      sectionTitle: "Documentation",
    },

    {
      title: "Manual",
      icon: BookOpenOutline,
      children: [
        {
          title: "Add manual",
          path: "/docs/user-manual/add",
          element: AddUserManualScreen,
          acl: {
            action: "manage",
            subject: "docs",
          },
        },
        ...(userManualLinks.length ? uLinks : []),
      ],
    },

    {
      title: "Technical Docs",
      icon: BookOpenOutline,
      acl: { action: "manage", subject: "settings" },
      children: [
        {
          title: "Add doc",
          path: "/docs/technical/add",
          element: AddTechnicalDocScreen,
          acl: {
            action: "manage",
            subject: "docs",
          },
        },
        ...(technicalDocsLinks.length ? tLinks : []),
      ],
    },
  ];

  return links as VerticalNavItemsType;
};

export default useDocsNav;
