import React, { useState, useCallback, useEffect, SyntheticEvent } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { Grid, Icon, Tab, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Spinner } from "../../../comps/utilities/spinner";
import DialogContainer from "../../../comps/dialogContainer";
import AdminForm from "../../../comps/admins/adminForm";
import ViewAdminLeft from "./viewTabs/viewAdminLeft";
import ViewInfo from "./viewTabs/viewInfo";

import { getAdmin } from "../../../store/apps/admin";

import { AppDispatch } from "../../../store";
import { Admin } from "../../../types/adminTypes";

const tabs = [
  {
    value: "info",
    label: "Info",
    icon: "description",
  },
  {
    value: "orders",
    label: "Orders",
    icon: "description",
  },
  {
    value: "timeline",
    label: "Timeline",
    icon: "description",
  },
];

const ViewAdminScreen = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();

  const [admin, setAdmin] = useState<Admin>();
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState("info");
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const handleChange = (event: SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  const toggleEditDialog = () => setEditDialogOpen((prev) => !prev);

  const fetchAdmin = useCallback(() => {
    if (!id) return;

    setLoading(true);
    dispatch(getAdmin(id))
      .unwrap()
      .then((res) => {
        setAdmin(res.admin);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, id]);

  useEffect(() => {
    fetchAdmin();
  }, [fetchAdmin]);

  const onEditCallback = () => {
    toggleEditDialog();
    fetchAdmin();
  };

  if (loading) return <Spinner />;

  if (!admin) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={3}>
        <ViewAdminLeft {...{ t, admin, toggleEditDialog }} />
      </Grid>

      <Grid item xs={12} md={9}>
        <TabContext value={tabValue}>
          <TabList
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            aria-label="forced scroll tabs"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            }}
          >
            {tabs.map((tab, i) => (
              <Tab
                key={i}
                value={tab.value}
                label={t(tab.label)}
                icon={<Icon sx={{ fontSize: "18px" }}>{tab.icon}</Icon>}
                sx={{
                  "& .MuiTab-iconWrapper": {
                    mb: 0,
                    mr: "0.25rem",
                  },
                }}
              />
            ))}
          </TabList>

          <Box mt={3}>
            <TabPanel sx={{ p: 0 }} value="info">
              <ViewInfo {...{ t, admin }} />
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>

      <DialogContainer open={editDialogOpen} handleClose={toggleEditDialog}>
        <Grid container>
          <Grid item xs={12}>
            <AdminForm type="edit" onSuccessCallback={onEditCallback} />
          </Grid>
        </Grid>
      </DialogContainer>
    </Grid>
  );
};

export default ViewAdminScreen;
