// ** React Imports
import { Dispatch, SetStateAction } from "react";

// import { useTranslation } from "react-i18next";

import { Icon, Box, Button } from "@mui/material";
import { GridRowId } from "@mui/x-data-grid";

import Translations from "../../layouts/components/Translations";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../layouts/components/acl/Can";

interface Props {
  selectedRows: GridRowId[];
  setSelectedRows: Dispatch<SetStateAction<GridRowId[]>>;
  toggleFormOpen: () => void;
  setMode: Dispatch<SetStateAction<"add" | "edit">>;
}

export const CategoriesTableHeader = (props: Props) => {
  const { toggleFormOpen, setMode } = props;

  // const { t } = useTranslation();
  const ability = useAbility(AbilityContext);


  const canCreate = ability.can('create', 'products');

  // const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
  //   useState<null | HTMLElement>(null);

  // const handleActionsMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
  //   setActionsMenuAnchorEl(event.currentTarget);
  // };

  // const handleActionsMenuClose = () => {
  //   setActionsMenuAnchorEl(null);
  // };

  const handleAdd = () => {
    setMode("add");
    toggleFormOpen();
  };

  return (
    <Box
      sx={{
        p: 5,
        pt: 7,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {/* <Button
            color="inherit"
            variant="outlined"
            aria-haspopup="true"
            disabled={selectedRows && selectedRows.length === 0}
            onClick={handleActionsMenuClick}
            endIcon={<Icon>expand_more</Icon>}
            aria-expanded={Boolean(actionsMenuAnchorEl) ? "true" : undefined}
            aria-controls={
              Boolean(actionsMenuAnchorEl) ? "product-list-actions" : undefined
            }
          >
            {t("Actions")}
          </Button>

          <Menu
            open={Boolean(actionsMenuAnchorEl)}
            anchorEl={actionsMenuAnchorEl}
            onClose={handleActionsMenuClose}
            id="product-list-actions"
            PaperProps={{
              sx: {
                minWidth: actionsMenuAnchorEl?.offsetWidth,
                maxWidth: actionsMenuAnchorEl?.offsetWidth,
              },
            }}
          ></Menu> */}

        <Box
          gap={3}
          sx={{ display: "flex", flexWrap: "wrap", alignItems: "center", justifyContent: "flex-end" }}
        >
          {/* <Button
              disabled
              color="secondary"
              variant="outlined"
              startIcon={<Icon>ios_share</Icon>}
            >
              <Translations text="Export" />
            </Button> */}

          <Button
            disabled={!canCreate}
            variant="contained"
            onClick={handleAdd}
            startIcon={<Icon>add</Icon>}
          >
            <Translations text="Add Category" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
