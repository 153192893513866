import { ThemeColorStatusType } from "../../types/common";

export const productStatuses = ["published", "draft", "inactive"] as const;

export const productOnSaleObj: ThemeColorStatusType = {
  false: "error",
  true: "success",
};

export const productStatusObj: ThemeColorStatusType = {
  published: "success",
  draft: "info",
  inactive: "warning",
};