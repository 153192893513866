// ** React Imports
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

// ** MUI Imports
import Grid from "@mui/material/Grid";

// ** Demo Components Imports
import { CustomerViewLeftScreen } from "./viewLeft";
import { CustomerViewRightScreen } from "./viewRight";
import { CustomerFormDialog } from "../../comps/customers/customer-form-dialog";

// ** Types Imports
import { OrderType } from "../../types/orderTypes";
import { CustomerLayoutProps, Customer, CustomerData, customerSchema } from "../../types/customerTypes";
import { AppDispatch } from "../../store";

import { fetchCustomer, fetchCustomerOrders } from "../../store/apps/customer";

import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";

import { clearSpace } from "../../helpers/clearSpace";
import { useAlertContext } from "../../hooks/useAlertContext";

export const CustomerViewScreen = () => {
  // ** State
  const [customerData, setCustomerData] = useState<Customer>();
  const [orderData, setOrderData] = useState<OrderType[]>([]);
  const [isOrderLoading, setIsOrderLoading] = useState<boolean>(true);
  const [editCustomerOpen, setEditCustomerOpen] = useState<boolean>(false);

  // ** Hooks
  const { t } = useTranslation();
  const { id } = useParams<CustomerLayoutProps>();
  const dispatch = useDispatch<AppDispatch>();
  const editCustomerForm = useForm<CustomerData>({
    values: customerData ? { ...customerData, password: "" } : undefined,
    resolver: yupResolver(customerSchema),
    context: {
      editMode: true
    }
  });
  const { showErrorAlert, showSuccessAlert } = useAlertContext();


  const toggleEditCustomerDialog = () => setEditCustomerOpen((prev) => !prev);

  const handleEditCustomer = (data: CustomerData) => {
    if(!customerData) return;

    const isEmpty = clearSpace(String(data.password)).length === 0

    // prevents empty string from being sent for update
    if (isEmpty || !data.password) {
      delete data.password
    }

    axios
      .patch(
        `${process.env.REACT_APP_SERVER_HOST}/customer/update/${customerData._id}`,
        data,
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data.customer);
        showSuccessAlert(t("Customer edited"))
        toggleEditCustomerDialog();
      })
      .catch(() => {
        setCustomerData(undefined);
        showErrorAlert(t("Failed to edit Customer"))
      });
  };

  useEffect(() => {
    if (id) {
      dispatch(fetchCustomer(id))
        .unwrap()
        .then((res) => setCustomerData(res.customer))
        .catch(() => setCustomerData(undefined));
    }

    setIsOrderLoading(true);

    if (id) {
      dispatch(fetchCustomerOrders(id))
        .unwrap()
        .then((res) => setOrderData(res.orders))
        .catch(() => setOrderData([]))
        .finally(() => setIsOrderLoading(false))
    }
  }, [id, dispatch]);

  if (!customerData) return null;

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={5} lg={4}>
        <CustomerViewLeftScreen
          {...{
            customerData,
            setCustomerData,
            toggleEditCustomerDialog,
          }}
        />
      </Grid>

      <Grid item xs={12} md={7} lg={8}>
        <CustomerViewRightScreen
          {...{
            customerData,
            setCustomerData,
            orderData,
            setOrderData,
            isOrderLoading,
            setIsOrderLoading,
            toggleEditCustomerDialog,
          }}
        />
      </Grid>

      <CustomerFormDialog
        title={t("Edit customer")}
        form={editCustomerForm}
        open={editCustomerOpen}
        toggle={toggleEditCustomerDialog}
        onSubmit={handleEditCustomer}
      />
    </Grid>
  );
};
