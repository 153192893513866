import {
  Card,
  Grid,
  Select,
  TextField,
  CardHeader,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { ChangeEvent } from "react";
import { adminRoles } from "../../../types/permission-types";

interface Props {
  t: any;
  role: string;
  handleRoleFilter: (e: SelectChangeEvent) => void;
  searchValue: string;
  handleSearchValue: (e: ChangeEvent<HTMLInputElement>) => void;
}

const AdminListFilters = (props: Props) => {
  const {
    t,
    role,
    handleRoleFilter,
    searchValue,
    handleSearchValue,
  } = props;

  return (
    <Card>
    <CardHeader title={t("Filter")} />

    <CardContent>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel id="">{t("Choose")}</InputLabel>

            <Select
              fullWidth
              value={role}
              sx={{
                mr: 4,
                mb: 2,
                textTransform: "capitalize",
              }}
              label={t("Role")}
              onChange={handleRoleFilter}
              labelId=""
            >
              <MenuItem value="">None</MenuItem>
              {adminRoles.map((item, i) => (
                <MenuItem
                  key={i}
                  value={item}
                  sx={{ textTransform: "capitalize" }}
                >
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            value={searchValue}
            placeholder={t("Find Admin")}
            onChange={handleSearchValue}
          />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}

export default AdminListFilters