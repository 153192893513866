// ** React Imports
import { useState, useEffect, useCallback, useMemo, ChangeEvent } from "react";
import { useSearchParams } from "react-router-dom";

// ** MUI Imports
import { Grid, Card, SelectChangeEvent } from "@mui/material";
import { DataGrid, GridColumns, GridRowId } from "@mui/x-data-grid";

import { useTranslation } from "react-i18next";

// ** Store & Actions Imports
import { useDispatch, useSelector } from "react-redux";

import {
  bulkDeleteProduct,
  deleteProduct,
  fetchData as fetchProducts,
} from "../../store/apps/product";
import useCompanies from "../../hooks/useCompanies";

// ** Types Imports
import { RootState, AppDispatch } from "../../store";
import { ProductT } from "../../types/productTypes";

// ** Custom Components Imports
import { ProductTableHeader } from "../../comps/products/table-header";
import DeleteProductDialog from "../../comps/products/delete-product-dialog";
import DialogContainer from "../../comps/dialogContainer";
import EditProductScreen from "./editProduct";
import { productStatuses } from "../../utils/products/data";

// ** Third Party Styles Imports
import "react-datepicker/dist/react-datepicker.css";
import { matchSorter } from "match-sorter";

import { useAlertContext } from "../../hooks/useAlertContext";
import ProductListFilters from "../../comps/products/list/product-list-filters";
import getProductListTableColumns from "../../comps/products/list/product-list-table-columns";
import { fetchProductCategories } from "../../store/apps/product-categories";
import { format } from "date-fns";
import { AbilityContext } from "../../comps/layouts/components/acl/Can";
import { useAbility } from "@casl/react";
import { useSettings } from "../../hooks/useSettings";

export const ProductListScreen = () => {
  // ** State
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedRows, setSelectedRows] = useState<GridRowId[]>([]);
  const [deleteProductOpen, setDeleteProductOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<ProductT>();
  const [isProductLoading, setIsProductLoading] = useState<boolean>(true);
  const [companyValue, setCompanyValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [tableData, setTableData] = useState<ProductT[]>([]);
  const [editProductOpen, setEditProductOpen] = useState(false);
  const [statusValue, setStatusValue] = useState("");

  // ** Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { product: store, productCategories } = useSelector(
    (state: RootState) => state
  );
  const ability = useAbility(AbilityContext);
  const { settings } = useSettings();
  const { configuredCompany } = settings;

  const companies = useCompanies();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const toggleEditProductDialog = () => setEditProductOpen((prev) => !prev);

  const toggleDeleteProductDialog = useCallback(() => {
    setDeleteProductOpen(!deleteProductOpen);
  }, [deleteProductOpen]);

  const handleEditProductDialogClose = () => {
    toggleEditProductDialog();
    setSelectedProduct(undefined);
  }

  const handleDeleteProductDialogClose = () => {
    toggleDeleteProductDialog();
    setSelectedProduct(undefined);
  }

  const handleSourceValue = (e: SelectChangeEvent) => {
    const { value } = e.target;
    const index = companies.findIndex((company) => company.id === value);
    setCompanyValue(value);

    const params = Object.fromEntries(searchParams.entries());

    if (index >= 0) {
      setSearchParams({ ...params, company: String(index) });
    } else {
      // @ts-ignore
      const { company, ...rest } = params;
      setSearchParams(rest);
    }
  };

  const handleStatusValue = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setStatusValue(value);

    const params = Object.fromEntries(searchParams.entries());

    if (value) {
      setSearchParams({ ...params, status: value });
    } else {
      // @ts-ignore
      const { status, ...rest } = params;
      setSearchParams(rest);
    }
  };

  const handleSearchValue = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value as string);
  };

  // fetch products with store
  //fetch product categories as well
  useEffect(() => {
    (async () => {
      setIsProductLoading(true);
      await dispatch(fetchProducts());
      await dispatch(fetchProductCategories());
      setIsProductLoading(false);
    })();
  }, [dispatch]);

  // data init / filtering
  useEffect(() => {
    let data = store.data;

    if (companyValue) {
      data = data.filter((t: ProductT) => t.company === companyValue);
    }

    if (statusValue) {
      data = data.filter((t: ProductT) => t.status === statusValue);
    }

    if (searchValue) {
      data = matchSorter(data, searchValue, { keys: ["name"] });
    }

    setTableData(data);
  }, [store.data, companyValue, searchValue, statusValue]);

  // use search params to update filters
  useEffect(() => {
    const params = Object.fromEntries(searchParams.entries());

    if (Object.hasOwn(params, "status")) {
      setStatusValue(params["status"]);
    }

    if (Object.hasOwn(params, "company")) {
      const company = companies[Number(params["company"])]?.id;
      company && setCompanyValue(company);
    }
  }, [searchParams, companies]);



  const handleDeleteSingleProduct = async () => {
    if (!selectedProduct) return;

    setIsProductLoading(true);
    await dispatch(deleteProduct(selectedProduct._id))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Product deleted"));
        setSelectedProduct(undefined);
      })
      .catch(() => {
        showErrorAlert("Failed to delete Product");
      })
      .finally(() => {
        setIsProductLoading(false);
        toggleDeleteProductDialog();
      });
  };

  const handleDeleteBulkProduct = async () => {
    if (selectedRows.length === 0) return;

    const ids = selectedRows as string[];

    setIsProductLoading(true);
    await dispatch(bulkDeleteProduct(ids))
      .unwrap()
      .then(() => {
        setSelectedRows([]);
        showSuccessAlert(t("Products deleted"));
      })
      .catch(() => {
        showErrorAlert("Failed to delete Product");
      })
      .finally(() => {
        setIsProductLoading(false);
        toggleDeleteProductDialog();
      });
  };

  const handleDeleteProduct = () => {
    if (selectedRows.length > 0) return handleDeleteBulkProduct();

    return handleDeleteSingleProduct();
  };

  const columns = useMemo<GridColumns<ProductT>>(
    () =>
      getProductListTableColumns({
        t,
        companies,
        setSelectedProduct,
        toggleEditProductDialog,
        toggleDeleteProductDialog,
        productCategories: productCategories.data,
        ability,
        configuredCompany,
      }),
    [
      companies,
      productCategories.data,
      t,
      toggleDeleteProductDialog,
      ability,
      configuredCompany,
    ]
  );

  const prepareExportData = () => {
    const data = tableData.map((item) => {
      const { timelineEntries, __v, ...relevantData } = item;

      return {
        ...relevantData,
      };
    });

    return data;
  };

  const exportData = useMemo(prepareExportData, [tableData]);
  const exportFilename = "products-" + format(new Date(), "dd-MM-yy");

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <ProductListFilters
          t={t}
          companies={companies}
          productStatuses={[...productStatuses]}
          companyValue={companyValue}
          searchValue={searchValue}
          statusValue={statusValue}
          handleSourceValue={handleSourceValue}
          handleSearchValue={handleSearchValue}
          handleStatusValue={handleStatusValue}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <DataGrid
            autoHeight
            rows={tableData}
            getRowId={(row) => row._id}
            columns={columns}
            disableSelectionOnClick
            checkboxSelection
            selectionModel={selectedRows}
            onSelectionModelChange={(rows) => setSelectedRows(rows)}
            loading={isProductLoading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={Number(pageSize)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            components={{ Toolbar: ProductTableHeader }}
            componentsProps={{
              toolbar: {
                selectedRows,
                setSelectedRows,
                exportData,
                exportFilename,
                toggleDeleteProductDialog,
                setIsProductLoading
              },
            }}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />
        </Card>
      </Grid>

      <DeleteProductDialog
        open={deleteProductOpen}
        handleClose={handleDeleteProductDialogClose}
        handleDelete={handleDeleteProduct}
        product={
          selectedProduct ? selectedProduct.name : `${selectedRows.length} products`
        }
      />

      {selectedProduct && (
        <>
          <DialogContainer
            open={editProductOpen}
            handleClose={handleEditProductDialogClose}
          >
            <EditProductScreen
              id={selectedProduct._id}
              handleClose={handleEditProductDialogClose}
            />
          </DialogContainer>
        </>
      )}
    </Grid>
  );
};
