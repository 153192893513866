import { Dispatch } from "redux";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AppPermissionsT, AppResourceActionsT, AppResourceT } from "../../../types/permission-types";
import { RootState } from "../..";

type InitialStateT = {
  permissions: AppPermissionsT
}

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

const initialState: InitialStateT = {
 permissions: {} as AppPermissionsT
};

export const fetchPerms = createAsyncThunk(
  "perms/get-all",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/admin/permissions`,
      { withCredentials: true }
    );

    const resp = response.data as AppPermissionsT;
    return resp;
  }
);

export const fetchOneUserPerms = createAsyncThunk(
  "perms/get-one",
  async (id: string) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/admin/permissions/${id}`,
      { withCredentials: true }
    );

    const resp = response.data as AppPermissionsT;
    return resp;
  }
);

export const updatePermsissions = createAsyncThunk(
  "perms/update",
  async (
    {
      data,
    }: {
      data: {
        resource: AppResourceT;
        action: AppResourceActionsT;
        allowed: boolean;
        adminID: string;
      };
    },
    { getState, dispatch }: Redux
  ) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/admin/permissions`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchPerms());

    return response.data;
  }
);

export const appPermissionssSlice = createSlice({
  name: "app-permissions-store",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPerms.fulfilled, (state, action) => {
      state.permissions = action.payload;
    });
  },
});

export default appPermissionssSlice.reducer;

export const selectPermissions = (state: RootState) => state.permissions.permissions;