import "./index.css";

import { LexicalComposer } from "@lexical/react/LexicalComposer";

import Editor from "./editor";
import PlaygroundNodes from "./nodes/PlaygroundNodes";
import { TableContext } from "./plugins/TablePlugin";
import PlaygroundEditorTheme from "./themes/PlaygroundEditorTheme";
import { forwardRef } from "react";

const DocsEditor = forwardRef((props, ref) => {
  const initialConfig = {
    editorState: undefined,
    namespace: "Playground",
    nodes: [...PlaygroundNodes],
    onError: (error: Error) => {
      throw error;
    },
    theme: PlaygroundEditorTheme,
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <TableContext>
        <div className="editor-shell">
          <Editor ref={ref} />
        </div>
      </TableContext>
    </LexicalComposer>
  );
});

export default DocsEditor;
