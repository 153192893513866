import { AbilityBuilder, PureAbility } from '@casl/ability'
import { AdminRolesT, AppPermissionsT, AppResourceActionsT, AppResourceT } from '../types/permission-types'

export type Subjects = string
export type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

export type AppAbilityT = PureAbility<[Actions, Subjects]>
export const AppAbility = PureAbility

export type ACLObj = {
  action: Actions;
  subject: AppResourceT | "all"
}

/**
 * Define permissions for a user:
 * - What pages/ui elements are accesssible.
 * - Permissions are made from a combination:
 *    - Default permissions for a role.
 *    - User Specific permissions(overrides the default)
 *
 * @example
 * const ability = useAbility(AbilityContext);
 * console.log(ability.rules) // to view defined rules
 */
const defineRulesFor = (role: AdminRolesT, subject: string, permissions: AppPermissionsT) => {
  const { can, build } = new AbilityBuilder<AppAbilityT>(AppAbility);

  const permission = {} as {
    [K in AppResourceT]: AppResourceActionsT[]
  };

  if (role === "superadmin") can("manage", [subject, "all"]);

  Object.keys(permissions).forEach((resource) => {
    let _resource = resource as AppResourceT;
    const resourcePermissions = permissions[_resource];

    const actions: AppResourceActionsT[] = [];

    // Collect allowed actions for this resource
    if (resourcePermissions.create) actions.push("create");
    if (resourcePermissions.view) actions.push("view");
    if (resourcePermissions.edit) actions.push("edit");
    if (resourcePermissions.delete) actions.push("delete");

    permission[_resource] = actions;

    // Allow specific actions for the current resource
    can(actions as unknown as Actions[] , [_resource]);
  });

  // Apply fallback rule only if no specific actions were set for the subject
  if (!Object.keys(permission).includes(subject)) {
    can("read", subject); // Fallback for the given subject
  }

  return build;
};

export const buildAbilityFor = (role: AdminRolesT, subject: string, permissions: AppPermissionsT): AppAbilityT => {
  const ability = defineRulesFor(role, subject, permissions)

  return ability({
    // See: https://casl.js.org/v6/en/guide/subject-type-detection#custom-subject-type-detection
    // @ts-ignore
    detectSubjectType: object => object!.type // mainly is string
  })
}

export const defaultACLObj: ACLObj = {
  action: 'read',
  subject: 'all'
}
