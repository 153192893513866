// ** Redux Imports
import { Dispatch } from "redux";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// ** Axios Imports
import axios from "axios";
import { PorezProductFormInputsT, PorezProductT, ProductFormInputs, ProductStatusT, ProductType } from "../../../types/productTypes";

interface Redux {
  getState: any;
  dispatch: Dispatch<any>;
}

// ** Fetch Products
export const fetchData = createAsyncThunk("product/getAll", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/product/get-all`,
    { withCredentials: true }
  );

  return response.data;
});

export const fetchPublishedProducts = createAsyncThunk("product/get-all-published", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_HOST}/product/get-all-published`,
    { withCredentials: true }
  );

  return response.data;
});

// ** Create Product
export const createProduct = createAsyncThunk(
  "product/create",
  async (data: ProductFormInputs | PorezProductFormInputsT, { dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/product/create`,
      {
        ...data,
      },
      { withCredentials: true }
    );

    dispatch(fetchData());
    return response.data;
  }
);

// ** Fetch Product
export const getProduct = createAsyncThunk(
  "product/get",

  async (id: number | string, { getState, dispatch }: Redux) => {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER_HOST}/product/get/${id}`,
      { withCredentials: true }
    );

    dispatch(fetchData());
    return response.data;
  }
);

// ** Update Product
export const updateProduct = createAsyncThunk(
  "product/update",

  async (
    args: {
      id: number | string;
      data: Partial<ProductType | PorezProductT>;
    },
    { dispatch }: Redux
  ) => {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_HOST}/product/update/${args.id}`,
      {
        ...args.data,
      },
      { withCredentials: true }
    );

    dispatch(fetchData());
    return response.data;
  }
);

// ** Delete Product
export const deleteProduct = createAsyncThunk(
  "product/delete",

  async (id: string, { dispatch }: Redux) => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER_HOST}/product/delete/${id}`,
      { withCredentials: true }
    );

    await dispatch(fetchData());
    return response.data;
  }
);

export const bulkDeleteProduct = createAsyncThunk(
  "product/bulk-delete",

  async (ids: string[], { dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/product/bulk-delete`,
      { ids },
      { withCredentials: true }
    );

    await dispatch(fetchData());
    return response.data;
  }
);


export const copyProduct = createAsyncThunk(
  "product/copy",

  async (selectedProducts: string[], { dispatch }: Redux) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/product/copy`,
      {
        selectedProducts,
      },
      { withCredentials: true }
    );

    await dispatch(fetchData());
    return response.data;
  }
);

export const bulkProductStatusChange = createAsyncThunk(
  "product/bulk-product-status-change",

  async (
    data: { selectedProducts: string[]; status: ProductStatusT },
    { dispatch }: Redux
  ) => {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER_HOST}/product/bulk-status-change`,
      data,
      { withCredentials: true }
    );

    await dispatch(fetchData());
    return response.data;
  }
);

export const appProductsSlice = createSlice({
  name: "appProduct",
  initialState: {
    data: [],
    total: 0,
    // params: {},
    allData: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchData.fulfilled, (state, action) => {
      state.data = action.payload.products;
      state.total = action.payload.total;
      // state.params = action.payload.params;
      state.allData = action.payload.allData;
    });
  },
});

export default appProductsSlice.reducer;
