// ** React Imports
import { Fragment } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

// ** Third Party Imports
import { useTranslation } from "react-i18next";
import { Controller, useFieldArray } from "react-hook-form";

interface Props {
  field: string;
  nested: boolean;
  preTitle?: string;
  title: string;
  required: boolean;
  control: any;
  errors: any;
}

const  CustomerAddressArray = (props: Props) => {
  // ** Props
  const { field, nested, preTitle, title, required, control, errors } = props;

  // ** Hooks
  const { t } = useTranslation();
  const { fields, remove, append } = useFieldArray({
    control,
    name: field,
  });

  return (
    <Fragment>
      <Grid container item alignItems="center" xs={12}>
        <Typography variant="body2" sx={{ fontWeight: !nested ? 600 : 400 }}>
          {preTitle ? `${t(preTitle)} ${t(title)}` : `${t(title)}`}
        </Typography>
        <IconButton
          size="small"
          // onClick={() => {
          //   if (field === "shippingAddress" || field === "billingAddress") {
          //     append(defaultValues.company[0]?.[field]);
          //   }
          // }}
          onClick={() => {
            append({
              address: "",
              city: "",
              postcode: "",
              country: {
                code: "",
                label: "",
                phone: "",
              },
            });
          }}
        >
          <Icon>add</Icon>
        </IconButton>
        {!required && fields.length > 0 && (
          <IconButton
            size="small"
            onClick={() => {
              remove(0);
            }}
          >
            <Icon>remove</Icon>
          </IconButton>
        )}
      </Grid>
      {fields.map((item, k) => (
        <Fragment key={item.id}>
          {k > 0 && (
            <Grid container item alignItems="center" xs={12}>
              <Typography variant="body2" sx={{ fontWeight: 400 }}>
                {`#${k + 1} ${t(title)}`}
              </Typography>
              <IconButton
                size="small"
                onClick={() => {
                  remove(k);
                }}
              >
                <Icon>remove</Icon>
              </IconButton>
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name={`${field}.${k}.address`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    required
                    value={value}
                    label={t("Address")}
                    onChange={onChange}
                    error={Boolean(errors?.[k]?.address)}
                  />
                )}
              />
              {errors?.[k]?.address && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {t(errors?.[k]?.address?.message as string)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name={`${field}.${k}.city`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    required
                    value={value}
                    label={t("City")}
                    onChange={onChange}
                    error={Boolean(errors?.[k]?.city)}
                  />
                )}
              />
              {errors?.[k]?.city && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {t(errors?.[k]?.city?.message as string)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name={`${field}.${k}.postcode`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    required
                    value={value}
                    label={t("ZIP")}
                    onChange={onChange}
                    error={Boolean(errors?.[k]?.postcode)}
                  />
                )}
              />
              {errors?.[k]?.postcode && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {t(errors?.[k]?.postcode?.message as string)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name={`${field}.${k}.country.code`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    label={t("Country code")}
                    onChange={onChange}
                    error={Boolean(errors?.[k]?.country?.code)}
                  />
                )}
              />
              {errors?.[k]?.country?.code && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {t(errors?.[k]?.country?.code?.message as string)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name={`${field}.${k}.country.label`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    label={t("Country label")}
                    onChange={onChange}
                    error={Boolean(errors?.[k]?.country?.label)}
                  />
                )}
              />
              {errors?.[k]?.country?.label && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {t(errors?.[k]?.country?.label?.message as string)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <Controller
                name={`${field}.${k}.country.phone`}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    value={value}
                    label={t("Country phone")}
                    onChange={onChange}
                    error={Boolean(errors?.[k]?.country?.phone)}
                  />
                )}
              />
              {errors?.[k]?.country?.phone && (
                <FormHelperText sx={{ color: "error.main" }}>
                  {t(errors?.[k]?.country?.phone?.message as string)}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Fragment>
      ))}
    </Fragment>
  );
};

export default CustomerAddressArray