// ** React Imports
import { useState, SyntheticEvent, Fragment } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

// ** Icons Imports
import CogOutline from "mdi-material-ui/CogOutline";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import EmailOutline from "mdi-material-ui/EmailOutline";
import LogoutVariant from "mdi-material-ui/LogoutVariant";
import AccountOutline from "mdi-material-ui/AccountOutline";
import MessageOutline from "mdi-material-ui/MessageOutline";
import HelpCircleOutline from "mdi-material-ui/HelpCircleOutline";

// ** Custom Components Imports
import { Avatar } from "../../../../avatar";

// ** Hooks Imports
import { useAuth } from "../../../../../hooks/useAuth";

// ** Utils Imports
import { getInitials } from "../../../../../helpers/get-initials";

// ** Type Imports
import { Settings } from "../../../../../context/settingsContext";
import { useNavigate } from "react-router-dom";

interface Props {
  settings: Settings;
}

// ** Styled Components
const BadgeContentSpan = styled("span")(({ theme }) => ({
  width: 8,
  height: 8,
  borderRadius: "50%",
  backgroundColor: theme.palette.success.main,
  boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
}));

const UserDropdown = (props: Props) => {
  // ** Props
  const { settings } = props;

  // ** States
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // ** Hooks
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  // ** Vars
  const { direction } = settings;

  const handleDropdownOpen = (event: SyntheticEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = (url?: string) => {
    if (url) {
      navigate(url);
    }
    setAnchorEl(null);
  };

  const styles = {
    py: 2,
    px: 4,
    width: "100%",
    display: "flex",
    alignItems: "center",
    color: "text.primary",
    textDecoration: "none",
    "& svg": {
      fontSize: "1.375rem",
      color: "text.secondary",
    },
  };

  const handleLogout = () => {
    logout();
    handleDropdownClose();
  };

  if (!user) return null;

  return (
    <Fragment>
      <Badge
        overlap="circular"
        onClick={handleDropdownOpen}
        sx={{ ml: 2, cursor: "pointer" }}
        badgeContent={<BadgeContentSpan />}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {user.avatar ? (
          <Avatar
            alt="Profile Pic"
            src={user.avatar}
            skin="light"
            sx={{
              width: 40,
              height: 40,
              fontWeight: 600,
              fontSize: "1rem",
            }}
            onClick={handleDropdownOpen}
          />
        ) : (
          <Avatar
            alt="Profile Pic"
            skin="light"
            sx={{
              width: 40,
              height: 40,
              fontWeight: 600,
              fontSize: "1rem",
            }}
            onClick={handleDropdownOpen}
          >
            {getInitials(user.displayName)}
          </Avatar>
        )}
      </Badge>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose()}
        sx={{ "& .MuiMenu-paper": { width: 230, mt: 4 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: direction === "ltr" ? "right" : "left",
        }}
      >
        <Box sx={{ pt: 2, pb: 3, px: 4 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Badge
              overlap="circular"
              badgeContent={<BadgeContentSpan />}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              {user.avatar ? (
                <Avatar
                  alt="Profile Pic"
                  src={user.avatar}
                  skin="light"
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                  onClick={handleDropdownOpen}
                />
              ) : (
                <Avatar
                  alt="Profile Pic"
                  skin="light"
                  sx={{
                    width: "2.5rem",
                    height: "2.5rem",
                    fontWeight: 600,
                    fontSize: "1rem",
                  }}
                  onClick={handleDropdownOpen}
                >
                  {getInitials(user.displayName)}
                </Avatar>
              )}
            </Badge>
            <Box
              sx={{
                display: "flex",
                ml: 3,
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>
                {user.displayName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontSize: "0.8rem", color: "text.disabled" }}
              >
                {user.role}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 0, mb: 1 }} />
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/apps/user/view/12")}
          disabled
        >
          <Box sx={styles}>
            <AccountOutline sx={{ mr: 2 }} />
            Profile
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/apps/email")}
          disabled
        >
          <Box sx={styles}>
            <EmailOutline sx={{ mr: 2 }} />
            Inbox
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/apps/chat")}
          disabled
        >
          <Box sx={styles}>
            <MessageOutline sx={{ mr: 2 }} />
            Chat
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{ p: 0 }}
          onClick={() =>
            handleDropdownClose(`/admin/account-settings/${user._id}`)
          }
        >
          <Box sx={styles}>
            <CogOutline sx={{ mr: 2 }} />
            Settings
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/pages/pricing")}
          disabled
        >
          <Box sx={styles}>
            <CurrencyUsd sx={{ mr: 2 }} />
            Pricing
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ p: 0 }}
          onClick={() => handleDropdownClose("/pages/faq")}
          disabled
        >
          <Box sx={styles}>
            <HelpCircleOutline sx={{ mr: 2 }} />
            FAQ
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
          <LogoutVariant
            sx={{ mr: 2, fontSize: "1.375rem", color: "text.secondary" }}
          />
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default UserDropdown;
