import { Grid } from "@mui/material";
import { NumericInput } from "./NumericInput";
import { Control, FieldErrors, useFormContext } from "react-hook-form";
import { ProductFormT } from "../../../types/productTypes";
import { useMemo } from "react";

interface Props {
  control: Control<ProductFormT, any>;
  t: any;
  errors: FieldErrors<ProductFormT>;
}

const DimensionsFields = ({ control, t, errors }: Props) => {
  const { watch } = useFormContext();

  const watchWidth = watch("width");
  const watchHeight = watch("height");

  const boardArea = useMemo(() => {
    const area = (watchWidth * watchHeight) / Math.pow(10, 6);
    return area;
  }, [watchWidth, watchHeight])

  return (
    <Grid item container spacing={5} xs={12}>
      <Grid item container spacing={5} xs={12} sm={6}>
        {/* Width */}
        <Grid item container spacing={5}>
          <NumericInput
            label={t("Min Width")}
            name="minWidth"
            {...{ control, errors, t }}
          />
          <NumericInput
            label={t("Max Width")}
            name="maxWidth"
            {...{ control, errors, t }}
          />
        </Grid>

        {/* Height */}
        <Grid item container spacing={5}>
          <NumericInput
            label={t("Min Height")}
            name="minHeight"
            {...{ control, errors, t }}
          />
          <NumericInput
            label={t("Max Height")}
            name="maxHeight"
            {...{ control, errors, t }}
          />
        </Grid>
      </Grid>

      <Grid item container xs={12} sm={6} spacing={5}>
        <NumericInput
          label={t("Width")}
          name="width"
          {...{ control, errors, t }}
        />

        <NumericInput
          label={t("Height")}
          name="height"
          {...{ control, errors, t }}
        />

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          Area = {boardArea}m<sup>2</sup>
        </Grid>
      </Grid>
    </Grid>
  );};

export default DimensionsFields;
