// ** React Imports
import { useState, useEffect, useCallback, useMemo, ChangeEvent } from "react";

// ** Utilities Imports
import debounce from "lodash.debounce";

// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { DataGrid, GridColumns } from "@mui/x-data-grid";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Store Imports
import { useDispatch, useSelector } from "react-redux";

// ** Actions Imports
import { deleteAdmin, fetchData } from "../../../store/apps/admin";

// ** Custom Components Imports
import { AdminTableHeader } from "../../../comps/admins/admin-table-header";
import DeleteAdminDialog from "../../../comps/admins/deleteAdminDialog";

// ** Types Imports
import { Admin } from "../../../types/adminTypes";
import { RootState, AppDispatch } from "../../../store";
import { useAlertContext } from "../../../hooks/useAlertContext";
import getAdminListTableColumns from "../../../comps/admins/list/admin-list-table-columns";
import AdminListFilters from "../../../comps/admins/list/admin-list-filters";
import { SelectChangeEvent } from "@mui/material";
import { AdminRolesT } from "../../../types/permission-types";

export const AdminListScreen = () => {
  // ** State
  const [value, setValue] = useState<string>("");
  const [pageSize, setPageSize] = useState<number>(10);
  const [selectedAdmin, setSelectedAdmin] = useState<Admin>();
  const [deleteAdminOpen, setDeleteAdminOpen] = useState<boolean>(false);
  const [isAdminLoading, setIsAdminLoading] = useState<boolean>(true);
  const [role, setRole] = useState<AdminRolesT | string>("");

  // ** Hooks
  const dispatch = useDispatch<AppDispatch>();
  const store = useSelector((state: RootState) => state.admin);
  const { t } = useTranslation();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();

  const debounceFetchData = useMemo(
    () =>
      debounce(async (val: string) => {
        setIsAdminLoading(true);
        await dispatch(
          fetchData({
            search: val,
          })
        );
        setIsAdminLoading(false);
      }, 1000),
    [dispatch]
  );

  useEffect(() => {
    debounceFetchData(value);
  }, [debounceFetchData, value]);

  const handleFilter = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value as string);
  }, []);

  const toggleDeleteAdminDialog = useCallback(() => {
    setDeleteAdminOpen(!deleteAdminOpen);
  }, [deleteAdminOpen]);

  const onDeleteAdminSuccess = async () => {
    await dispatch(
      fetchData({
        search: "",
      })
    );
  };

  const handleDeleteAdmin = async () => {
    if (selectedAdmin) {
      setIsAdminLoading(true);
      await dispatch(deleteAdmin(selectedAdmin._id))
        .unwrap()
        .then(() => {
          showSuccessAlert(t("Admin deleted"));
          onDeleteAdminSuccess();
        })
        .catch(() => {
          showErrorAlert(t("Failed to delete Admin"));
        })
        .finally(() => {
          setIsAdminLoading(false);
          toggleDeleteAdminDialog();
        });
    }
  };

  const handleRoleFilter =  (e: SelectChangeEvent) => {
    setRole(e.target.value)
  }

  const columns = useMemo<GridColumns<Admin>>(
    () =>
      getAdminListTableColumns({
        t,
        setSelectedAdmin,
        toggleDeleteAdminDialog,
      }),
    [t, setSelectedAdmin, toggleDeleteAdminDialog]
  );

  return (
    <Grid container spacing={6}>
      <Grid item xs={12}>
        <AdminListFilters
          {...{
            t,
            role,
            handleRoleFilter,
            searchValue: value,
            handleSearchValue: handleFilter
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <Card>
          <DataGrid
            autoHeight
            disableSelectionOnClick
            rows={store.data}
            getRowId={(row) => row._id}
            columns={columns}
            loading={isAdminLoading}
            pagination
            rowsPerPageOptions={[10, 25, 50]}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            components={{ Toolbar: AdminTableHeader }}
            sx={{ "& .MuiDataGrid-columnHeaders": { borderRadius: 0 } }}
          />
        </Card>
      </Grid>

      {selectedAdmin && (
        <DeleteAdminDialog
          open={deleteAdminOpen}
          handleClose={toggleDeleteAdminDialog}
          handleDelete={handleDeleteAdmin}
          admin={selectedAdmin.displayName}
        />
      )}
    </Grid>
  );
};
