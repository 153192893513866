// ** React Imports
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";

// ** MUI Imports
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

// ** Third Party Imports
import { useTranslation } from "react-i18next";

// ** Store Imports
import { useDispatch } from "react-redux";
import { deleteOrder } from "../../../store/apps/order";

// ** Custom Component Imports
import DeleteOrderDialog from "../deleteOrderDialog";

// ** Types Imports
import { AppDispatch } from "../../../store";
import { OrderType, OrdersViewModeT } from "../../../types/orderTypes";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import TableExporter from "../../table/table-exporter";
import { AbilityContext } from "../../layouts/components/acl/Can";
import { useAbility } from "@casl/react";

interface Props {
  selectedRows: OrderType[];
  viewMode: OrdersViewModeT;
  handleViewModeChange: (event: any, view: OrdersViewModeT) => void;
  exportData: any[];
  exportFilename: string;
}

export const OrderListTableHeader = (props: Props) => {
  // ** Props
  const { selectedRows, viewMode, handleViewModeChange, exportData, exportFilename } = props;

  // ** States
  const [deleteOrderOpen, setDeleteOrderOpen] = useState(false);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] =
    useState<null | HTMLElement>(null);

  // ** Hooks
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const ability = useAbility(AbilityContext);

  const canCreate = ability.can('create', 'orders');
  const canDelete = ability.can('delete', 'orders');

  const handleActionsMenuClick = (event: MouseEvent<HTMLButtonElement>) => {
    setActionsMenuAnchorEl(event.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchorEl(null);
  };

  const handleDeleteRows = () => {
    toggleDeleteOrderDialog();
    handleActionsMenuClose();
  };

  const toggleDeleteOrderDialog = () => setDeleteOrderOpen((prev) => !prev);

  const handleDeleteOrder = () => {
    selectedRows.forEach((selectedRow) => {
      dispatch(deleteOrder(selectedRow._id));
    });
    toggleDeleteOrderDialog();
  };

  return (
    <Box
      sx={{
        p: 5,
        pb: 3,
        width: "100%",
        gap: 4,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <>
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <Button
            color="inherit"
            variant="outlined"
            aria-haspopup="true"
            disabled={(selectedRows && selectedRows.length === 0) || !canCreate}
            onClick={handleActionsMenuClick}
            endIcon={<Icon>expand_more</Icon>}
            aria-expanded={Boolean(actionsMenuAnchorEl) ? "true" : undefined}
            aria-controls={
              Boolean(actionsMenuAnchorEl) ? "order-list-actions" : undefined
            }
          >
            {t("Actions")}
          </Button>

          <ToggleButtonGroup
            size="small"
            color="primary"
            value={viewMode}
            exclusive
            onChange={handleViewModeChange}
            aria-label="text alignment"
          >
            <ToggleButton value="table">
              <Icon>view_list_outline</Icon>
            </ToggleButton>
            <ToggleButton value="kanban">
              <Icon>grid_view_outline</Icon>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        <Menu
          open={Boolean(actionsMenuAnchorEl)}
          anchorEl={actionsMenuAnchorEl}
          onClose={handleActionsMenuClose}
          id="order-list-actions"
          PaperProps={{
            sx: {
              minWidth: actionsMenuAnchorEl?.offsetWidth,
              maxWidth: actionsMenuAnchorEl?.offsetWidth,
            },
          }}
        >
          <MenuItem value="Delete" disabled={!canDelete} onClick={handleDeleteRows}>
            {t("Delete")}
          </MenuItem>
        </Menu>

        <DeleteOrderDialog
          open={deleteOrderOpen}
          handleClose={toggleDeleteOrderDialog}
          handleDelete={handleDeleteOrder}
          orders={selectedRows.map(
            (selectedRow) => `#${selectedRow.orderNumber}`
          )}
        />
      </>

      <Box
        gap={3}
        sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}
      >
        <TableExporter disabled={!canCreate} {...{ exportData, exportFilename }} />

        <Button disabled={!canCreate} variant="contained" component={Link} to="/order/add">
          {t("Add order")}
        </Button>
      </Box>
    </Box>
  );
};
