import { Box, Card, CardHeader, CardContent, Typography, IconButton } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store";
import { deleteDoc, fetchAllDocs, fetchDoc, fetchDocsNavLink } from "../../store/apps/docs";
import { DocT, DocTypeT } from "../../types/doc-types";
import { Delete, FileEditOutline } from "mdi-material-ui";
import DocsForm from "./docs-form";
import { AbilityContext } from "../layouts/components/acl/Can";
import { useAbility } from "@casl/react";
import DeleteDialog from "../delete-dialog";
import { useTranslation } from "react-i18next";
import { useAlertContext } from "../../hooks/useAlertContext";

interface Props {
  docType: DocTypeT
}

const DocsViewer = (props: Props) => {
  const { docType } = props;

  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const ability = useAbility(AbilityContext);

  const canManage = ability.can('manage', 'docs');

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { showErrorAlert, showSuccessAlert } = useAlertContext();

  const id = useMemo(() => {
    const paths = location.pathname.split("/");
    const id = paths.at(-1);

    return id;
  }, [location.pathname]);

  const docsViewerRef = useRef<HTMLDivElement | null>(null);

  const [doc, setDoc] = useState<DocT | null>(null);
  const [editMode, setEditMode] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const toggleEditMode = () => setEditMode((prev) => !prev);
  const toggleDeleteDialog = () => setDeleteOpen((prev) => !prev);

  const handleDocContentFetchCallback = () => {
    if (!id) return;

    dispatch(fetchDoc(id))
      .unwrap()
      .then((res) => setDoc(res));
  }

  const handleDocContentFetch = useCallback(handleDocContentFetchCallback, [dispatch, id]);

  const handleDeleteSuccess = async () => {
    if (doc?.type === "technical") {
      navigate("/docs/technical/add");
    } else {
      navigate("/docs/user-manual/add");
    }

    // usually done in the delete async thunk
    // but it is here because they are used to build the app routes for docs
    // so they are here to prevent a 404 on delete
    // and are then called after redirect, to update ui and routes
    await dispatch(fetchDocsNavLink());
    await dispatch(fetchAllDocs());
  };

  const handleDelete = async () => {
    if (!id) return;

    await dispatch(deleteDoc(id))
      .unwrap()
      .then(async () => {
        showSuccessAlert(t("Admin deleted"));

        await handleDeleteSuccess()
      })
      .catch(() => {
        showErrorAlert(t("Failed to delete Admin"));
      })
      .finally(() => {
        toggleDeleteDialog();
      });
  };

  const handleEditSuccess = () => {
    setEditMode(false);
    handleDocContentFetch();
  }

  useEffect(() => {
    handleDocContentFetch();
  }, [handleDocContentFetch]);

  // update doc content when an image fails to load
  useEffect(() => {
    const ref = docsViewerRef.current;

    if (ref) {
      const imgElements = ref.querySelectorAll('img');
      const brokenImagePath = '/images/misc/image-broken.svg';
      const brokenImageAttritbutes = (img: HTMLImageElement) => {
        img.src = brokenImagePath;
        img.alt = "broken img";
        img.setAttribute(
          'style',
          'height: 200px; width: 200px; opacity: 0.2;'
        );
      }

      imgElements.forEach((img) => {
        if (!img.complete || img.naturalWidth === 0) {
          brokenImageAttritbutes(img);
        }

        img.onerror = () => {
          brokenImageAttritbutes(img);
        };
      });

    }
  }, [doc?.content])

  if (!doc) return null;

  if (editMode) {
    return (
      <DocsForm
        type="edit"
        docType={docType}
        editID={id}
        editCallback={handleEditSuccess}
      />
    );
  }

  return (
    <>
      <Card sx={{
        "& img": {
          width: "auto",
          maxWidth: "100%"
        }
      }}>
        <CardHeader
          component={() => (
            <Box
              sx={{
                p: 5,
                pb: 0,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  textTransform: "capitalize",
                  width: "50%",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {doc.pageNavTitle}
              </Typography>

              {canManage && (
                <Box>
                  <IconButton onClick={toggleEditMode}>
                    <FileEditOutline />
                  </IconButton>

                  <IconButton onClick={toggleDeleteDialog}>
                    <Delete />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        />
        <CardContent>
          <Box
            ref={docsViewerRef}
            dangerouslySetInnerHTML={{ __html: doc.content }}
            sx={{
              background: (theme) => theme.palette.background.default,
              minHeight: 400,
              padding: 4,
              borderRadius: 1,
            }}
          />
        </CardContent>
      </Card>

      <DeleteDialog
        open={deleteOpen}
        title="document"
        item={doc.pageNavTitle}
        handleClose={toggleDeleteDialog}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default DocsViewer;
