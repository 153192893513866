import {
  GridActionsCellItem,
  GridColumns,
  GridPreProcessEditCellProps,
  GridRowId,
  GridRowParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid";
import {
  AddOrderLineItemT,
  AddOrderListLineItemType,
  BasketPriceTypeTemp,
  LineItemType,
  OrderLineItemT,
} from "../../../types/orderTypes";
import { PrintCell } from "./print-cell";
import ProductCell from "./product-cell";
import { Box, Icon } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { ProductType } from "../../../types/productTypes";
import ObjectID from "bson-objectid";
import { Customer } from "../../../types/customerTypes";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import { isVariobelLineItem } from "./helpers";

type Params<T> = {
  t: any;
  showErrorAlert: (message: string, duration?: number | undefined) => void;
  itemList: T[];
  setItemList: Dispatch<SetStateAction<T[]>>;
  products: ProductType[];
  tax: number;
  type: "add" | "edit";
  basketPrice?: BasketPriceTypeTemp;
  setBasketPrice?: Dispatch<SetStateAction<BasketPriceTypeTemp>>;
  customer: Customer | null,
  isVariobelOnlyField: boolean
};

const baseFields = [
  "product",
  "qty",
  "price",
  "priceDPH",
  "actions"
];

const porezFields = [
  "width",
  "height"
]

export const getAddEditOrderTableColumns = <
  T extends OrderLineItemT | AddOrderLineItemT
>(
  props: Params<T>
): GridColumns<T> => {
  const {
    t,
    showErrorAlert,
    itemList,
    setItemList,
    products,
    tax,
    type,
    basketPrice,
    setBasketPrice,
    customer,
    isVariobelOnlyField
  } = props;

  const handleCopy = (id: GridRowId) => () => {
    let row = itemList.find((row) => {
      if ("id" in row) {
        return row.id === id;
      }

      return row._id === id;
    });

    let updatedItems = [...itemList, row] as T[];

    // Update id so it's unique
    updatedItems = updatedItems.map((row, i) => {
      if ("id" in row) {
        return {
          ...row,
          id: i + 1,
        };
      }

      const _id = new ObjectID();
      const lineItem = {
        ...row,
        _id,
      };

      return lineItem;
    });

    setItemList(updatedItems);
  };

  const columns: GridColumns<T> = [
    {
      flex: 0.1,
      minWidth: 100,
      field: "name",
      headerName: t("Name"),
      editable: true,
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "product",
      headerName: t("Product"),
      renderCell: ({ row }) => (
        <ProductCell
          lineItem={row}
          type={type}
          products={products}
          itemList={itemList}
          setItemList={setItemList}
          tax={tax}
          basketPrice={basketPrice}
          setBasketPrice={setBasketPrice}
          customer={customer}
        />
      ),
    },
    {
      flex: 0.05,
      minWidth: 120,
      field: "print",
      headerName: t("Print"),
      renderCell: ({ row }) => {
        if (!isVariobelLineItem(row)) return null;

        return (
          <PrintCell
            lineItem={row}
            itemList={
              itemList as unknown as (LineItemType | AddOrderListLineItemType)[]
            }
            setItemList={
              setItemList as unknown as Dispatch<
                SetStateAction<(LineItemType | AddOrderListLineItemType)[]>
              >
            }
          />
        );},
      valueGetter: ({ row }: { row: OrderLineItemT | AddOrderLineItemT }) =>
        isVariobelLineItem(row) ? row.print.value : null,
    },
    {
      flex: 0.05,
      minWidth: 125,
      field: "glassHeight",
      headerName: t("Height"),
      type: "number",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (!params.value) return;

        return `${params.value.toFixed(0)} mm`;
      },
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const { product } = params.row as
          | LineItemType
          | AddOrderListLineItemType;
        let minHeight = 200;
        let maxHeight = 1200;

        if (product) {
          minHeight = product.minHeight;
          maxHeight = product.maxHeight;
        }

        const interval: number[] = [minHeight, maxHeight];
        const hasError: boolean =
          (parseInt(params.props.value) as number) < Math.min(...interval) ||
          (parseInt(params.props.value) as number) > Math.max(...interval);
        if (hasError) {
          setTimeout(
            () =>
              showErrorAlert(
                t(`The value must be in the interval ${interval.join(" to ")}.`)
              ),
            800
          );
        }

        return { ...params.props, error: hasError };
      },
    },
    {
      flex: 0.05,
      minWidth: 125,
      field: "glassWidth",
      headerName: t("Width"),
      type: "number",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (!params.value) return;

        return `${params.value.toFixed(0)} mm`;
      },
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const { product } = params.row as
          | LineItemType
          | AddOrderListLineItemType;
        let minWidth = 200;
        let maxWidth = 2700;

        if (product) {
          minWidth = product.minWidth;
          maxWidth = product.maxWidth;
        }

        const interval: number[] = [minWidth, maxWidth];
        const hasError: boolean =
          (parseInt(params.props.value) as number) < Math.min(...interval) ||
          (parseInt(params.props.value) as number) > Math.max(...interval);
        if (hasError)
          setTimeout(
            () =>
              showErrorAlert(
                t(`The value must be in the interval ${interval.join(" to ")}.`)
              ),
            800
          );

        return { ...params.props, error: hasError };
      },
    },
    {
      flex: 0.05,
      minWidth: 125,
      field: "area",
      headerName: t("Area"),
      type: "number",
      renderCell: (params) => (
        <Box
          component="div"
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          {params.value!.toFixed(2)} m<sup>2</sup>
        </Box>
      ),
      valueGetter: ({ row }: { row: OrderLineItemT | AddOrderLineItemT}) =>
        isVariobelLineItem(row) ? (row.glassWidth * row.glassHeight) / Math.pow(10, 6) : null,
    },
    {
      flex: 0.1,
      minWidth: 125,
      field: "ppu",
      headerName: t("Price per unit"),
      type: "number",
      valueFormatter: (params: GridValueFormatterParams) => {
        if (params.value == null) {
          return "0 €";
        }
        return currencyFormatter(Number(params.value));
      },
    },
    { // porez only
      flex: 0.1,
      minWidth: 125,
      field: "width",
      headerName: t("Width"),
      type: "number",
      editable: true,
      valueFormatter: (params: GridValueFormatterParams) => params.value ?? 0
    },
    { // porez only
      flex: 0.1,
      minWidth: 125,
      field: "height",
      headerName: t("Height"),
      type: "number",
      editable: true,
      valueFormatter: (params: GridValueFormatterParams) => params.value ?? 0
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "qty",
      headerName: t("Number of items"),
      type: "number",
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        const interval: number[] = [1, 100];
        const hasError: boolean =
          (parseInt(params.props.value) as number) < Math.min(...interval) ||
          (parseInt(params.props.value) as number) > Math.max(...interval);
        if (hasError)
          showErrorAlert(
            `The value must be in the interval ${interval.join(" to ")}.`
          );

        return { ...params.props, error: hasError };
      },
    },
    {
      flex: 0.05,
      minWidth: 150,
      field: "price",
      headerName: t("without VAT"),
      type: "number",
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        if (params.value === 0) {
          return "0 €";
        }
        return currencyFormatter(Number(params.value));
      },
    },
    {
      field: "priceDPH",
      headerName: t("Total VAT"),
      width: 150,
      type: "number",
      editable: false,
      renderCell: (params) => {
        const { row } = params;
        const { price } = row;

        if (!basketPrice) return currencyFormatter(price);

        const { taxRate } = basketPrice;
        const taxAmount = (taxRate / 100) * price;
        const priceWithDPH = price + taxAmount;

        return currencyFormatter(priceWithDPH);
      },
    },
    {
      field: "action",
      type: "actions",
      headerName: t("Actions"),
      width: 110,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Icon>content_copy</Icon>}
          id="tenth_demo_step"
          label="Kopírovať"
          onClick={handleCopy(params.id)}
        />,
      ],
    },
  ];

  const porezColumns = columns.filter((col) => {
    const includeField  = [...baseFields, ...porezFields].includes(col.field);
    return includeField;
  });

  const variobelColumns = columns.filter((col) => {
    const includeField  = [...porezFields].includes(col.field);
    return !includeField;
  });

  const selectedColumns = isVariobelOnlyField ? variobelColumns : porezColumns;

  return selectedColumns;
};
