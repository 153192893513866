import React from "react";
import { GridActionsCellItem, GridColumns } from "@mui/x-data-grid";
import { Box, Button, Icon, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { Chip } from "../../chip";
import { ThemeColor } from "../../@core/layouts/types";
import { ICompany } from "../../../hooks/useCompanies";
import { ProductT } from "../../../types/productTypes";
import {
  productOnSaleObj,
  productStatusObj,
} from "../../../utils/products/data";
import { ProductCategoryT } from "../../../types/product-category-type";
import { currencyFormatter } from "../../../helpers/currency-formatter";
import { AnyAbility } from "@casl/ability";
import { ConfigCompaniesT } from "../../../context/settingsContext";

interface CellType {
  row: ProductT;
}

interface Props {
  t: any;
  companies: ICompany[];
  productCategories: ProductCategoryT[];
  setSelectedProduct: React.Dispatch<
    React.SetStateAction<(ProductT) | undefined>
  >;
  toggleEditProductDialog: () => void;
  toggleDeleteProductDialog: () => void;
  ability: AnyAbility;
  configuredCompany: ConfigCompaniesT;
}

const baseFields = [
  "name",
  "description",
  "category",
  "company",
  "status",
  "price",
  "actions"
];

const porezFields = [
  "code",
  "surface",
  "image",
  "thickness",
  "condition"
]

const getProductListTableColumns = (props: Props): GridColumns<ProductT> => {
  const {
    t,
    companies,
    productCategories,
    setSelectedProduct,
    toggleEditProductDialog,
    toggleDeleteProductDialog,
    ability,
    configuredCompany
  } = props;

  const canUpdate = ability.can('edit', 'products');
  const canDelete = ability.can('delete', 'products');

  const columns = [
    {
      flex: 0.15,
      minWidth: 50,
      field: "name",
      headerName: t("Product name"),
      renderCell: ({ row }: CellType) => {
        const Image = ({ src }: { src: string }) => (
          <img
            alt="preview"
            src={src}
            style={{
              objectFit: "contain",
              width: "10%",
              height: "100%",
            }}
          />
        );

        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              gap: 2,
              display: "flex",
              alignItems: "center",
            }}
          >
            {/* Variobel - images
              Porez - image
          */}
            {row.images && (
              <Image src={row.images.length ? row.images[0] : ""} />
            )}

            {row.image && <Image src={row.image} />}
            <Typography
              variant="body2"
              component={Link}
              to={`/products/preview/${row._id}`}
            >
              {row.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "code",
      headerName: t("Code"),
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">{row.code}</Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "surface",
      headerName: t("Surface"),
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">{row.surface}</Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "price",
      headerName: t("Price"),
      renderCell: ({ row }: CellType) => (
        <Typography variant="body2">{currencyFormatter(row.price)}</Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "category",
      headerName: t("Category"),
      renderCell: ({ row }: CellType) => {
        const getCategory = () => {
          if (!row.category) return "None";

          const category = productCategories.find(
            (cat) => cat._id === row.category
          );
          if (!category) return "None";

          return category.name;
        };

        const category = getCategory();

        return <Typography variant="body2">{category}</Typography>;
      },
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "company",
      headerName: t("Company"),
      renderCell: ({ row }: CellType) => {
        const company = companies.filter((c) => c.id === row.company)[0];

        return (
          <Chip
            skin="light"
            size="small"
            label={row.company && company ? company.name : ""}
            color={
              (row.company && company
                ? company.colorCode
                : "error") as ThemeColor
            }
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "18px" },
            }}
          />
        );
      },
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "status",
      headerName: t("Status"),
      renderCell: ({ row }: CellType) => {
        return (
          <Chip
            skin="light"
            size="small"
            label={row.status}
            color={productStatusObj[row.status]}
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "18px" },
            }}
          />
        );
      },
    },
    {
      flex: 0.1,
      minWidth: 50,
      field: "onSale",
      headerName: t("On sale"),
      renderCell: ({ row }: CellType) => {
        return (
          <Chip
            skin="light"
            size="small"
            label={String(row.onSale)}
            color={productOnSaleObj[String(row.onSale)]}
            sx={{
              textTransform: "capitalize",
              "& .MuiChip-label": { lineHeight: "18px" },
            }}
          />
        );
      },
    },
    {
      flex: 0.1,
      minWidth: 20,
      sortable: false,
      field: "actions",
      headerName: t("Actions"),
      type: "actions",
      getActions: ({ row }: CellType) => [
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">visibility</Icon>}
          label={t("View")}
          // mui issue - fix requires migrating to latest mui-x pkg
          //@ts-expect-error
          component={Link}
          to={`/products/preview/${row._id}`}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{
            "& .MuiListItemIcon-root": { minWidth: 0 },
            textTransform: "none",
          }}
          icon={<Icon fontSize="small">edit</Icon>}
          label={t("Edit")}
          //@ts-expect-error
          component={Button}
          disabled={!canUpdate}
          onClick={() => {
            setSelectedProduct(row);
            toggleEditProductDialog();
          }}
        />,
        <GridActionsCellItem
          showInMenu
          sx={{ "& .MuiListItemIcon-root": { minWidth: 0 } }}
          icon={<Icon fontSize="small">delete</Icon>}
          label={t("Delete")}
          disabled={!canDelete}
          onClick={() => {
            setSelectedProduct(row);
            toggleDeleteProductDialog();
          }}
        />,
      ],
    },
  ];


  const variobelColumns = columns.filter((col) => {
    const includeField  = [...porezFields].includes(col.field);
    return !includeField;
  });

  const porezColumns = columns.filter((col) => {
    const includeField  = [...baseFields, ...porezFields].includes(col.field);
    return includeField;
  });

  const selectedColumns = configuredCompany === "variobel" ? variobelColumns : porezColumns;

  return selectedColumns;
};

export default getProductListTableColumns;
