import { ThemeColor } from "../comps/@core/layouts/types";

export const adminRoles = [
  "superadmin",
  "admin",
  "editor",
  "user",
] as const;

export const appResources = [
  "customers",
  "products",
  "orders",
  "email",
  "order-status",
  "translations",
  "email-setup",
  "companies",
  "users",
] as const;

export const appResourceActions = ["create", "view", "edit", "delete"] as const;

export type AppResourceActionsT = (typeof appResourceActions)[number];

export type AppResourceT = (typeof appResources)[number];

export type AdminRolesT = typeof adminRoles[number];

type RoleThemeColorsT = {
  [K in AdminRolesT]: ThemeColor;
}

export const adminRoleThemeColors: RoleThemeColorsT = {
  superadmin: "success",
  admin: "info",
  editor: "warning",
  user: "primary",
}

type PermissionT = {
  [K in AppResourceActionsT]: boolean;
};

export type AppPermissionsT = {
  [k in AppResourceT]: PermissionT;
};


