import React from "react";

// ** MUI Imports
import {
  Card,
  Box,
  CardContent,
  Typography,
  Button,
  Icon,
} from "@mui/material";

// ** Third Party Imports
import { format, parseISO } from "date-fns";

// ** Types
import { Admin } from "../../../../types/adminTypes";

// ** Custom Components
import { Avatar } from "../../../../comps/avatar";

// ** Utils Import
import { getInitials } from "../../../../helpers/get-initials";
import { Chip } from "../../../../comps/chip";
import { adminRoleThemeColors } from "../../../../types/permission-types";

interface Props {
  t: any;
  admin: Admin;
  toggleEditDialog: () => void;
}

const ViewAdminLeft = (props: Props) => {
  const { t, admin, toggleEditDialog } = props;

  return (
    <Card>
      <CardContent
        sx={{
          pt: 15,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Avatar
          skin="light"
          variant="rounded"
          sx={{
            width: 120,
            height: 120,
            fontWeight: 600,
            mb: 4,
            fontSize: "3rem",
          }}
        >
          {getInitials(`${admin.displayName}`)}
        </Avatar>

        <Typography variant="h6">{admin.displayName}</Typography>
      </CardContent>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h6">{t("Details")}</Typography>

        <Box
          py={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Email")}:`}
            </Typography>

            <Typography variant="body2">{admin.email}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("Date registered")}:`}
            </Typography>

            <Typography variant="body2">
              {admin.registerDate
                ? format(parseISO(admin.registerDate), "MMM d, yyyy")
                : "NA"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ mr: 2, color: "text.primary" }}
            >
              {`${t("IsRegistered")}:`}
            </Typography>

            <Icon
              sx={{
                fontWeight: "body2.fontWeight",
                fontSize: "body2.fontSize",
                color: "text.secondary",
              }}
            >
              {admin.isRegistered ? "check_box" : "check_box_outline_blank"}
            </Icon>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography sx={{ mr: 2, fontWeight: 500, fontSize: "0.875rem" }}>
              {t("Role")}:
            </Typography>
            <Chip
              skin="light"
              size="small"
              label={admin.role}
              color={adminRoleThemeColors[admin.role]}
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": { lineHeight: "18px" },
              }}
            />
          </Box>
        </Box>

        <Button variant="contained" onClick={toggleEditDialog}>
          Edit
        </Button>
      </CardContent>
    </Card>
  );
};

export default ViewAdminLeft;
