import yup from "../../helpers/customYup";
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Box,
  Button,
  CardActions,
  TextField,
  FormHelperText,
  LinearProgress,
  MenuItem,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AppDispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { createDoc, fetchAllDocs, fetchDoc, updateDoc } from "../../store/apps/docs";
import { useAlertContext } from "../../hooks/useAlertContext";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { DocTypeT } from "../../types/doc-types";
import DocsEditor from "./docs-editor";

type Inputs = {
  title: string;
  content: string;
  parentID: string | null;
};

type PayloadT = {
  pageNavTitle: string;
  type: DocTypeT;
  content: string;
};

type EditPayloadT = { id: string; data: PayloadT };

type EditorRefT = {
  clearEditorContent: () => void;
  setEditorContent: (htmlString: string) => void;
}

interface Props {
  type: "add" | "edit";
  docType: DocTypeT;
  editID?: string;
  editCallback?: () => void;
}

const InputsValidation = yup.object({
  title: yup.string().required().min(1),
  content: yup.string().required(),
});

const DocsForm = (props: Props) => {
  const { type, docType, editID, editCallback } = props;
  const docsEditorRef = useRef<EditorRefT | null>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const { showSuccessAlert, showErrorAlert } = useAlertContext();

  const formMethods = useForm<Inputs>({
    defaultValues: {
      title: "",
      content: "",
    },
    mode: "onBlur",
    resolver: yupResolver(InputsValidation),
  });
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { docs } = useSelector(
    (state: RootState) => state.docs
  );

  const handleSetEditorContent = (data: string) => {
    if (docsEditorRef.current) {
      docsEditorRef.current.setEditorContent(data);
    }
  };

  const handleClearEditorContent = () => {
    if (docsEditorRef.current) {
      docsEditorRef.current.clearEditorContent();
    }
  };

  // edit mode data fetch
  useEffect(() => {
    if (editID && type === "edit") {
      dispatch(fetchDoc(editID))
        .unwrap()
        .then((res) => {
          setValue("title", res.pageNavTitle);
          setValue("content", res.content);
          setValue("parentID", res.parentID);
          handleSetEditorContent(res.content);
        });
    }
  }, [dispatch, editID, setValue, type]);

  // all docs fetch
  useEffect(() => {
    dispatch(fetchAllDocs());
  }, [dispatch]);

  const handleFormReset = () => {
    reset();
    handleClearEditorContent();
  };

  const handleCreateDoc = (payload: PayloadT) => {
    dispatch(createDoc(payload))
      .unwrap()
      .then((res) => {
        const path = `/docs/${res.type}/${res._id}`;

        handleFormReset();
        showSuccessAlert(t("Document created"));
        navigate(path);
      })
      .catch(() => {
        showErrorAlert(t("Failed to create document"));
      });
  };

  const handleUpdateDoc = (payload: EditPayloadT) => {
    dispatch(updateDoc(payload))
      .unwrap()
      .then(() => {
        showSuccessAlert(t("Document updated"));
        editCallback && editCallback();
      })
      .catch(() => {
        showErrorAlert(t("Failed to update document"));
      });
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const payload = {
      pageNavTitle: data.title,
      type: docType,
      content: data.content,
      parentID: data.parentID === "null" ? null : data.parentID,
    };

    const editPayload = {
      id: editID ?? "",
      data: payload,
    };

    if (type === "add") {
      return handleCreateDoc(payload);
    }

    return handleUpdateDoc(editPayload);
  };

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardHeader
            component={() => (
              <Box
                sx={{
                  p: 5,
                  pb: 0,
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                }}
              >
                <Typography variant="h6">
                  {type === "add" ? t("Add new document") : t("Edit document")}
                </Typography>

                <Box sx={{ width: "100%", display: "flex", gap: 2 }}>
                  <Box sx={{ width: "100%" }}>
                    <TextField
                      label="Document Title"
                      variant="outlined"
                      fullWidth
                      {...register("title")}
                      {...(type === "edit" && {
                        InputLabelProps: { shrink: true },
                      })}
                    />
                    {errors.title && errors.title.message && (
                      <FormHelperText sx={{ color: "error.main", mt: 2 }}>
                        {t(errors.title.message)}
                      </FormHelperText>
                    )}
                  </Box>

                  <Box sx={{ width: "40%" }}>
                    <Controller
                      name="parentID"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={field.value === null ? "null" : field.value}
                          select
                          label={t("Parent Document")}
                          fullWidth
                        >
                          <MenuItem value="null">None</MenuItem>
                          {docs
                            .filter((d) => d.type === docType && editID !== d._id)
                            .map((item, index) => (
                              <MenuItem key={index} value={item._id}>
                                {item.pageNavTitle}
                              </MenuItem>
                            ))}
                        </TextField>
                      )}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          />

          <CardContent>
            <DocsEditor ref={docsEditorRef} />

            {errors.content && errors.content.message && (
              <FormHelperText sx={{ color: "error.main", mt: 1 }}>
                {t(errors.content.message)}
              </FormHelperText>
            )}

            {isSubmitting && <LinearProgress />}
          </CardContent>

          <CardActions>
            <Button
              variant="contained"
              sx={{ marginRight: "auto" }}
              type="submit"
              disabled={isSubmitting}
            >
              Save
            </Button>
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};

export default DocsForm;
